import { supabaseBrowserClient } from '@utils/client'

export async function signInWithPassword(credentials: { email: string; password: string }) {
  const { data, error } = await supabaseBrowserClient().auth.signInWithPassword(credentials)
  if (error) {
    const msg = `failed to signIn`
    console.error(msg, error)
    throw new Error(msg, { cause: { name: 'Error de Autenticação', message: error.message } })
  }

  return data
}

export async function signOut(): Promise<void> {
  const { error } = await supabaseBrowserClient().auth.signOut()
  if (error) {
    const msg = `failed to SignOut`
    console.error(msg, error)
    throw new Error(msg, { cause: { name: 'Error de Autenticação', message: error.message } })
  }
}
