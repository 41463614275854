import { SupabaseContext } from '@services/supabase.service'
import { ISupabaseContext } from '@services/supabase.types'
import { useContext } from 'react'

export const useSupabase = () => {
  const context = useContext<ISupabaseContext>(SupabaseContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
